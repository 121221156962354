import React from "react"
import Logo from "../../icons/PDF-logo-vector.svg"
import Close from "../../icons/close-icon-popup.svg"

import styled from "styled-components"
import { useRef } from "react"
import { useEffect } from "react"
import TryPdf from "../shared/TryPdf"
import { useState, useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import { navigate } from "gatsby"

const BackgroundFiller = styled.div`
  position: relative;
  margin: 0 auto;
  margin-left: 0;

  height: 40px;
`

const HeaderS = styled.div`
  width: 80%;

  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa615c;
  margin: auto;
  max-width: 1440px;

  /* @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
  } */

  svg {
    cursor: pointer;
    s @media (max-width: 768px) {
      margin-bottom: 3%;
    }
  }
`

const HeaderLimit = styled.div`
  width: 100%;
  background: #fa615c;
  position: fixed;
  position: fixed;
  top: 0px;
  z-index: 9999;
  opacity: 1;
  display: flex;

  transition: opacity 0.5s;

  /* @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
  } */
  border-bottom: 1px solid #e1e1e1;
`

const NavBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #000000;

    display: flex;
  }
  width: 100%;
  color: white;

  @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center:
  }
`

const Button = styled.button`
  background: ${props => props.bgColor};
  border-radius: 28px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  border: none;
  width: 18%;
  height: 50px;
  margin-left: 5%;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 50%;

    margin: 0;
  }
`
const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: white;
  font-weight: 600;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`

const CloseWrap = styled.div`
  width: 32px;
  height: 32px;
  background-color: #fa615c;
  border-radius: 16px;
  display: flex:
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 16px;

  }
  
  svg{
  margin-left: 9px;
  margin-top: 9px;
  path {
    stroke: white;
  }
  }
cursor: pointer;
`

function StickyCtaClosable({ date }) {
  const { setScroll } = useContext(GlobalAuthContext)
  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  const [url, setUrl] = useState("")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  const first = useRef(null)

  useEffect(() => {
    if (window.location.href.includes("lp1")) setUrl("/lp1")
    if (window.location.href.includes("lp2")) setUrl("/lp2")
  }, [])

  return (
    <BackgroundFiller>
      <HeaderLimit ref={first}>
        <HeaderS>
          <NavBar>
            <Text>{date}</Text>
          </NavBar>
          <CloseWrap
            onClick={() => {
              first.current.style.display = "none"
              setClosedSticky(true)
            }}
          >
            <Close />
          </CloseWrap>
        </HeaderS>
        {myPopUp === "show" && <TryPdf close={closePopup} />}
      </HeaderLimit>
    </BackgroundFiller>
  )
}

export default StickyCtaClosable
