const date = new Date()

function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0)
}

function calculateSaleDate(date) {
  let currentDay = date.getDate()
  let currentMonth = date.getMonth()
  let currentYear = date.getFullYear()
  if (currentDay > 15) {
    let modifiedDate = getLastDayOfMonth(date.getFullYear(), date.getMonth())
    console.log("lastDay", modifiedDate)
    let extension =
      getLastDayOfMonth(currentYear, currentMonth).getDate().toString()[1] ==
      "1"
        ? "st"
        : "th"
    return (
      date.toLocaleString("default", { month: "long" }) +
      " " +
      modifiedDate.getDate() +
      extension
    )
  } else {
    return date.toLocaleString("default", { month: "long" }) + " " + 15 + "th"
  }
}

function saleDate() {
  return calculateSaleDate(date)
}

export default saleDate
